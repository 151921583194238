@import 'src/assets/variables/index.scss';

.e-status {
  display: inline-block;
  color: white;
  border-radius: 5px;
  padding: 4px 10px;
  line-height: 1.2;

  @media (max-width: $md) {
    font-size: 11px;
  }
}

.e-status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  color: white;
  border-radius: 50%;

  @media (max-width: $md) {
    font-size: 9px;
  }
}

.e-status,
.e-status-icon {
  &.success {
    background-color: $darker-green;
  }

  &.success-light {
    background-color: $light-green;
  }

  &.danger {
    background-color: $lighter-red;
  }

  &.danger-light {
    background-color: $pink;
  }

  &.frozen {
    color: $text-main;
    background-color: $sky;
  }

  &.disabled {
    background-color: $darker-grey;
  }
}
