@import 'variables/index.scss';

.d-none {
	display: none !important;
}

.d-block{
    display: block !important;
}

.d-flex{
    display: flex !important;
}

.d-md-none {
	@media screen and (max-width: $md) {
		display: none !important;
	}
}

.d-md-block {
	@media screen and (max-width: $md) {
		display: block !important;
	}
}

.d-md-flex {
	@media screen and (max-width: $md) {
		display: flex !important;
	}
}

.d-tb-none{
	@media screen and (max-width: $tb) {
		display: none !important;
	}
}

.d-tb-block{
	@media screen and (max-width: $tb) {
		display: block !important;
	}
}

.d-tb-flex{
	@media screen and (max-width: $tb) {
		display: flex !important;
	}
}


.d-tablet-none {
	@media screen and (max-width: $tablet) {
		display: none !important;
	}
}

.d-tablet-flex {
	@media screen and (max-width: $tablet) {
		display: flex !important;
	}
}