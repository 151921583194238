@import 'tippy.js/dist/tippy.css';

@import 'tippy.js/themes/light-border.css'; // theme: 'light-border'
@import 'tippy.js/animations/scale-subtle.css'; // animation: 'scale-subtle'

@import 'src/assets/variables/index.scss';

.tippy-box {
  border-radius: 5px;
}

.tippy-box[data-theme~='light-border'] {
  border: 0.5px solid rgba(220, 231, 252, 0.6);
}

.tippy-content {
  font:
    400 14px / 20px Roboto,
    'Helvetica Neue',
    sans-serif;
  color: $text-main;
  padding: 5px 15px;
}
