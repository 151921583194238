@import 'src/assets/variables/index.scss';

.cdk-overlay-pane .mat-snack-bar-container {
  padding: 0;
  margin-top: 40px;
}

.mat-snack-bar-handset .mat-snack-bar-container {
  padding: 0 !important;
  position: absolute;
  top: 70px;
  box-shadow: none;
  padding: 0 !important;
  background-color: transparent;

  @media (max-width: $md) {
    display: flex;
    justify-content: center;
    transition: 0.5s;

    div {
      width: max-content;

      app-snack-bar {
        padding: 10px 40px;
        border-radius: 5px;
      }
    }
  }
}
