$header-height: 55px;
$max-page-width: 1860px;

@mixin paddings($type: 'all', $top: 30px, $side: 60px) {
    @if $type == 'all' { 
        padding: $top $side;
    }
    @if $type == 'top' { 
        padding-top: $top;
    }
}
