// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import 'app/shared/modules/tooltip/scss/tooltip';
@import 'src/assets/button.scss';
@import 'src/assets/inputs.scss';
@import 'src/assets/helpers-media.scss';
@import 'src/assets/helpers-indent.scss';
@import 'app/shared/modules/status/scss/status';
@import 'app/shared/modules/snack-bar/scss/snack-bar';
@import '../node_modules/cropperjs/dist/cropper.min.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$student-app-primary: mat.define-palette(mat.$indigo-palette);
$student-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$student-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$student-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $student-app-primary,
      accent: $student-app-accent,
      warn: $student-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($student-app-theme);

@font-face {
  font-family: 'Roboto Medium';
  src: url(assets/fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Bold';
  src: url(assets/fonts/Roboto-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Regular';
  src: url(assets/fonts/Roboto-Regular.ttf) format('truetype');
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  font-family: 'Roboto Regular';
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
}

// https://www.expiredqueues.com/css/stop-zoom-in-on-input-focus-on-mobile-devices/
// fix auto zoom in IOS mobile
@media screen and (max-width: $md) {
  input,
  select,
  textarea {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
  button {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

//Spinner
mat-progress-spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.spinner-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1100;
}

.mat-progress-spinner circle {
  stroke: #0075ff;
}

.spinner-overlay.dark-theme {
  background-color: rgba(#1d2125, 0.6);

  .mat-progress-spinner circle {
    stroke: $dt-sky;
  }
}

.password-toggle-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 0px;
  mask-image: url('/assets/icons/eye-off.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: $text-main;
}

.password-toggle-icon.eye-on {
  mask-image: url('/assets/icons/eye-on.svg');
}

.dark-theme .password-toggle-icon {
  background-color: $dt-text-main;
}

// Scroll
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 50px;
}
::-webkit-scrollbar-thumb {
  background: $light-grey;
  border-radius: 50px;
}

.mat-dialog-container {
  border-radius: 10px !important;
  padding: 0 !important;

  .mat-icon {
    color: $text-main;
  }
}

.table-sort {
  cursor: pointer;
}

td,
th,
p {
  transition: background-color 300ms;

  &.sort-cell {
    &:hover {
      background-color: $light-blue;
    }
  }
  .fa-caret-down {
    display: none;
    cursor: pointer;
    margin-left: 10px;
    transform: rotate(0);
  }

  &.asc .fa-caret-down,
  &.desc .fa-caret-down {
    display: inline-block;
  }

  &.desc .fa-caret-down {
    transform: rotate(180deg);
  }
}

.mat-dialog-container {
  overflow: unset !important;
}

.cdk-overlay-backdrop.dialog-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

@media screen and (max-width: 780px) {
  .cdk-overlay-backdrop.dialog-backdrop {
    display: none;
  }

  .cdk-overlay-pane.dialog {
    max-width: 100% !important;
    width: 100%;
    margin-top: $header-height;
    height: calc(100svh - $header-height);
  }

  .mat-dialog-container {
    border-radius: 0 !important;
  }
}

.calendarPanel {
  margin-top: 20px;
  background: white;
  padding: 0 7px;
  border: 1px solid #297abf;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  border-radius: 5px;
  border: 1px solid $blue;
}

.mat-expansion-indicator::after {
  border: none;
  transform: none !important;
  height: 12px;
  width: 12px;
  mask-image: url('/assets/icons/input-select-arrow.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: $text-main;
}

.dark-theme {
  .mat-expansion-panel {
    background-color: $dt-grey;
    color: $dt-text-main;

    .mat-expansion-panel-header-title {
      color: $dt-text-main;
    }

    .mat-expansion-indicator::after {
      background-color: $dt-text-main;
    }
  }
}

.dark-theme {
  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background-color: $dt-disabled;
  }
}

.cdk-overlay-container.dark-theme {
  .mat-dialog-container .mat-icon {
    color: $dt-text-main;
  }

  .mat-dialog-close:hover {
    background-color: $dt-darker-grey;
  }

  .mat-menu-panel {
    background-color: $dt-grey;

    .mat-menu-item {
      color: $dt-text-main;
    }
  }

  .menu .menu__item {
    background: $dt-grey;
    color: $dt-text-main !important;
  }

  modal {
    color: $dt-text-main;

    .balance-title,
    .balance-sum {
      color: $dt-text-main;
    }
  }

  .modal-footer {
    background-color: $dt-grey;
  }
}
