@import 'variables/index.scss';

$box-shadow-active-state:
  inset -2px -2px 10px rgba(195, 213, 230, 0.25),
  inset 2px 2px 4px rgba(22, 31, 65, 0.25),
  inset 4px 4px 10px rgba(44, 46, 95, 0.25);

@mixin button-base-styles {
  cursor: pointer;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  word-break: break-all;
  transition: 300ms background-color;
  outline: none;
  @include size(100%, 100%);
  @include button-font(13px, 500);
}

@mixin button-font($size, $weight, $color: white) {
  font-family: 'Roboto';
  font-size: $size;
  font-weight: $weight;
  line-height: 87%;
  text-transform: uppercase;
  color: $color;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin stroked-hover-colors {
  background: $blue;
  color: white;
}

@mixin stroked-active-colors {
  @include stroked-hover-colors;
  box-shadow: $box-shadow-active-state;
}

@mixin stroked-disable-colors {
  color: $text-main;
  border-color: $text-main;
  opacity: 0.6;
}

.primary {
  @include button-base-styles;

  background: $blue;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);

  &:not(:disabled):not(:active):hover {
    opacity: 0.7;
  }

  &:disabled {
    background: $darker-grey;
  }

  &:not(:disabled):active {
    box-shadow: $box-shadow-active-state;
  }
}

.stroked {
  @include button-base-styles;
  color: $blue;
  border: 1px solid $blue;

  &:not(:disabled):not(:active):hover {
    @include stroked-hover-colors;
  }

  &:disabled {
    @include stroked-disable-colors;
  }

  &:not(:disabled):active {
    @include stroked-active-colors;
  }
}

.uncolored {
  @extend .stroked;
  @include stroked-disable-colors;

  // TODO Need to clarify this states
  &:not(:disabled):not(:active):hover {
    background: white;
    @include stroked-disable-colors;
  }

  &:disabled {
    @include stroked-disable-colors;
  }

  &:not(:disabled):active {
    background: white;
    @include stroked-disable-colors;
  }
}

.reset {
  @extend .stroked;
  @include size(205px, 40px);
  position: relative;
  color: $text-main;
  padding-left: 20px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 8.9px);
    left: 18px;
    mask-image: url('icons/filter-alt-off.svg');
    mask-repeat: no-repeat;
    background: $text-main;
    @include size(24px, 24px);
  }

  &:not(:disabled):not(:active):hover {
    &::after {
      background: white;
    }
  }

  &:disabled {
    &::after {
      background: $text-main;
    }
  }

  &:not(:disabled):active {
    &::after {
      background: white;
    }
  }
}

.btn {
  cursor: pointer;
  width: 150px;
  height: 45px;
  padding: 7px;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Roboto';
  border-radius: 5px;
  border: none;
  background-color: transparent;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  word-break: break-all;
  transition: 300ms background-color;
}

.btn-primary {
  background-color: $blue;

  &:hover {
    background-color: $light-blue;
  }

  &:active {
    box-shadow:
      inset -2px -2px 10px rgba(195, 213, 230, 0.25),
      inset 2px 2px 4px rgba(22, 31, 65, 0.25),
      inset 4px 4px 10px rgba(44, 46, 95, 0.25);
  }

  &:disabled,
  .disabled {
    pointer-events: none;
    background-color: $darker-grey;
  }

  &.btn-outline {
    background-color: transparent;
    border: 1px solid $blue;
    color: $blue;

    &:disabled,
    .disabled {
      pointer-events: none;
      color: $darker-grey;
      border-color: $darker-grey;
    }
  }
}

.btn-delete {
  background-color: red;

  &.btn-outline {
    background-color: transparent;
    border: 1px solid red;
    color: red;
  }

  &:disabled,
  .disabled {
    pointer-events: none;
    border-color: $darker-grey;
    color: $darker-grey;
  }
}

.btn-settings {
  position: relative;
  color: $text-main !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  width: 100%;
  font-weight: 500;
  text-transform: none;
  padding: 15px;
  letter-spacing: 1px;
  font-size: 16px;

  &:before,
  &:after {
    border-right: 2px solid;
    content: '';
    display: block;
    height: 8px;
    margin-top: -6px;
    position: absolute;
    transform: rotate(135deg);
    right: 25px;
    top: 50%;
    width: 0;
  }

  &:after {
    margin-top: -1px;
    transform: rotate(45deg);
  }
}

.btn-cross {
  cursor: pointer;
  position: absolute;
  top: 20%;
  right: 6%;
  font-size: 16px;
  color: grey;
  background-color: transparent;
  border: none;
}

.reset-btn {
  width: 205px;
  height: 40px;
  border-color: $blue !important;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  transition: 300ms;

  &::after {
    content: '';
    display: block;
    position: absolute;
    mask-image: url('icons/filter-alt-off.svg');
    mask-repeat: no-repeat;
    background-color: $text-main;
    mask-size: cover;
    width: 24px;
    height: 24px;
    top: calc(50% - 11.9px);
    left: 8px;
  }

  &:disabled {
    color: $darker-grey;
    border-color: $darker-grey !important;

    &::after {
      background-color: $darker-grey;
    }
  }

  &:not(:disabled):hover {
    background-color: $blue;
    color: white;

    &::after {
      background-color: white;
    }
  }

  .mat-button-wrapper {
    margin-left: 15px;
  }
}

.dark-theme {
  .stroked:not(:disabled):not(:active):hover,
  .primary,
  .btn-primary {
    background-color: $dt-sky;
  }

  .btn-primary:disabled {
    background-color: $dt-disabled;
  }

  .stroked,
  .btn-primary.btn-outline {
    border-color: $dt-sky;
    color: $dt-sky;
    background: none;
  }

  .primary:disabled {
    background-color: $dt-darker-grey;
  }

  .reset-btn:not(:disabled):hover {
    background-color: $dt-sky;
  }
}
