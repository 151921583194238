@import 'variables/index.scss';
.phone-wrapper {
  position: relative;
}

input[type='password'] {
  width: 92% !important;
  padding-right: 8% !important;
}

.input {
  &.mat-form-field {
    width: 100%;
    padding: 0;
  }

  &.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em 0;
    min-height: 45px;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline {
    color: $blue;
    min-height: 45px;
    background-color: white;
    border-radius: 5px;
  }

  //for center label
  &.mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.3em;
    margin-top: 1px;
  }

  &.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label {
    transform: translateY(-1.4em) scale(0.75);
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline {
    color: $blue-opacity;
  }

  &.mat-form-field-disabled.mat-form-field-appearance-outline
    .mat-form-field-outline,
  &.mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-outline {
    color: $grey-disable;
  }

  &.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
    color: $blue-touched;
  }

  &.input-required::after {
    content: '*';
    color: red;
    position: absolute;
    top: -7px;
    left: calc(100% - 10px);
  }

  &.input-icon {
    &.mat-form-field .mat-form-field-infix {
      padding-left: 55px;
    }

    &.mat-form-field .mat-form-field-label {
      left: 55px;
    }
  }

  &.mat-form-field-appearance-outline .mat-form-field-prefix {
    position: absolute;
    padding: 13px 15px 15px 2px;
    border-right: 1px solid $blue;
    height: 100%;
  }

  &.input-valid.mat-form-field-appearance-outline .mat-form-field-prefix {
    border-color: $blue;
  }

  &.input-valid.mat-form-field.mat-focused .mat-form-field-label {
    color: $blue;
  }

  &.input-disabled.mat-form-field-appearance-outline .mat-form-field-prefix {
    border-color: $grizzly;
  }

  //star required
  .mat-placeholder-required {
    display: none;
  }

  &.textarea.mat-form-field {
    height: 130px;

    .mat-form-field-flex {
      height: 100%;
    }

    .mat-form-field-infix {
      height: 100%;
    }

    textarea {
      height: 100%;
      resize: none;
    }
  }

  &.textarea.mat-form-field-appearance-outline .mat-form-field-wrapper {
    height: 100%;
  }
}

.slide {
  &.mat-slide-toggle .mat-slide-toggle-ripple {
    width: 0;
  }
  .mat-slide-toggle-bar {
    border-radius: 25px;
    height: 20px;
    width: 40px;
    background-color: $wan;
  }
  .mat-slide-toggle-thumb {
    height: 17px;
    width: 17px;
    margin-top: 4px;
    margin-left: 3px;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(18px, 0, 0);
  }

  &.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: $blue;
  }

  &.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: white;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mat-checkbox-inner-container {
    height: 26px;
    width: 26px;
    background-color: white;
  }

  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    color: $text-main;
    background-color: transparent;
  }
  .mat-checkbox-checkmark {
    top: 3px;
    left: 3px;
    width: 20px;
  }

  .mat-checkbox-checkmark-path {
    stroke: black !important;
  }
  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 5px;
    border-width: 1px;
    border-color: $blue;
  }

  .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
  .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: transparent;
  }

  .mat-checkbox-disabled {
    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-color: $darker-grey;
    }

    .mat-checkbox-checkmark {
      opacity: 0.2;
    }
  }
}

.chip {
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    cursor: pointer;
    background-color: transparent;
    color: $blue;
    border: 1px solid $blue;
    transition: 300ms;

    &:hover,
    &:focus {
      background-color: $blue;
      color: white;

      .mat-icon {
        color: white;
        opacity: 1;
      }
    }
  }

  .mat-chip.mat-standard-chip::after {
    background: transparent;
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: $blue;
    opacity: 0.6;
  }
}

.dark-theme .chip {
  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
    color: $dt-sky;
    border: 1px solid $dt-sky;

    &:hover,
    &:focus {
      background-color: $dt-sky;
      color: white;

      .mat-icon {
        color: white;
        opacity: 1;
      }
    }
  }

  .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
    color: $dt-sky;
  }
}

.switcher {
  width: 100%;
  &__button {
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $blue;
    padding: 7px 0;

    &:first-child {
      border-right: 1px solid $blue;
    }
  }

  &__title {
    position: relative;
  }

  &__clear {
    position: absolute;
    top: 4px;
    right: -34px;
    width: 11px;
    height: 11px;
    background-image: url('/assets/icons/cancel-filters-cross.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $blue !important;
}

.mat-menu_selected {
  background: $sky;
}

.mat-menu-item,
.mat-ripple {
  height: 40px !important;
  line-height: 100% !important;
}

.mat-menu-item,
.cdk-menu-item {
  background: #fff;
}

.mat-menu-content .item .mat-menu-item:hover,
.cdk-menu-item:hover {
  background: $sky;
}

.mat-menu-panel {
  min-height: 28px !important;
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-select-trigger {
  height: 100% !important;
}

.mat-select-arrow {
  border: 0px !important;
  width: 20px !important;
  height: 24px !important;
  mask-image: url('/assets/icons/input-select-arrow.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: $text-main;
  margin: 10px 0 0 0 !important;
}

.mat-option:focus:not(.mat-option-disabled),
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-option:hover:not(.mat-option-disabled) {
  background: rgba(220, 231, 252, 0.5) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.dark-theme {
  .slide.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
    background-color: $dt-sky;
  }

  .input.mat-form-field-appearance-outline .mat-form-field-outline {
    background-color: $dt-grey;
    color: $dt-blue;
  }
  .input.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
    color: $dt-sky;
  }

  .mat-input-element:disabled,
  .mat-select-value,
  .mat-select-arrow,
  .mat-date-range-input-separator,
  .mat-select-placeholder,
  button.active,
  .mat-option.mat-active {
    color: $dt-text-main;
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-label {
    color: $darker-grey;
  }

  .mat-form-field-label,
  ::placeholder {
    color: $darker-grey !important;
  }

  .mat-select-arrow {
    background-color: $dt-text-main;
  }

  .slide .mat-slide-toggle-bar {
    background-color: $dt-darker-grey;
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-outline {
    color: $dt-darker-grey;
  }

  textarea {
    background-color: $dt-grey;
    border-color: $dt-sky;
    color: $dt-text-main;
  }

  .mat-option,
  mat-dialog-container {
    background-color: $dt-grey;
    color: $dt-text-main;

    .e-status.frozen {
      background-color: $dt-sky;
      color: white;
    }
  }

  .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    background-color: $dt-blue !important;
  }

  .mat-select-panel {
    background-color: $dt-grey;

    &::-webkit-scrollbar-thumb {
      background-color: $dt-disabled;
    }
  }

  .mat-menu-item:hover,
  .cdk-menu-item:hover {
    background: $dt-sky !important;
  }

  .checkbox .mat-checkbox-checkmark-path {
    stroke: white !important;
  }

  .checkbox .mat-checkbox-inner-container {
    background: $dt-grey;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: $dt-text-main;
  }
}
