$wan: #e0e0e0;
$light-grey: #f1f2f4;
$grizzly: #d1d1d1;
$darker-grey: #bdbdbd;
$light-green: #aae8c3;
$darker-green: #39a182;
$sky: #dce7fc;
$blue: #297abf;
$blue-opacity: rgba(41, 122, 191, 0.5);
$blue-touched: rgba(41, 122, 191, 1);
$grey-disable: rgba(189, 189, 189, 0.4);
$light-blue: #4697dc;
$pink: #ef7979;
$text-main: #242524;
$light-yellow: #f2c94c;
$vital-green: #198754;
$tooltip-info: #f8fafe;

$dt-grey: #1d2125;
$dt-darker-grey: #8b98a6;
$dt-lighter-grey: rgba(139, 152, 166, 0.2);
$dt-blue: #1d304f;
$dt-sky: #628fda;
$dt-lighter-sky: rgba(98, 143, 218, 0.2);
$dt-vital-green: #4f8e71;
$dt-text-main: white;
$dt-disabled: #464c52;

$dt-canceled: #a93642;
$dt-approved: #b7e6c6;
$dt-completed: #4f8e71;

//Todo when refactoring names colors to think about colors with opacity
$lighter-red: rgba(220, 53, 69, 0.9);
$lighter-vital-green: rgba(25, 135, 84, 0.5);
$lighter-blue: rgba(41, 122, 191, 0.7);
