@import 'variables/index.scss';

.m-b-5{
	margin-bottom: 5px !important;
}

.m-b-10{
    margin-bottom: 10px !important;
}

.m-b-15{
    margin-bottom: 15px !important;
}
